/**
 * This dictionary is coupled with the pages folder.
 * Therefore, if you rename any file there, it should be renamed here as well
 *
 * ps: every path should start with `/` prefix
 *
 */
export const pagesPaths = {
  homeClient: { path: '/', name: 'Home' },
};

/**
 * This dictionary contains urls that are outside our project
 *
 * ps: every path should start with `/` prefix
 *
 */
export const externalPaths = {
  portal: { name: 'Portal do profissional de saúde', path: 'https://portal.vigilantesdosono.com/' },
  community: { name: 'Comunidade', path: 'https://comunidade.vigilantesdosono.com' },
  anvisa: { name: 'Certificado Anvisa', path: 'https://consultas.anvisa.gov.br/#/saude/25351114673202391/' },
};

/**
 * This dictionary contains other URLS (not listed on pagesPath, neither externalPaths)
 *
 * ps: every path should start with `/` prefix
 *
 */

//TODO TELEHEALTH: joguei esses paths para o global/utils
//a ideia é o utilizar o de la e remover daqui
export const appPaths: Record<string, { name: string; path: string }> = {
  internalSessions: { name: 'Sessões internas', path: '/chatfuel/sessao-interna/' },
  sessions: { name: 'Sessões', path: '/chatfuel/sessao/' },
  tips: { name: 'Dicas', path: '/chatfuel/dicas/' },
  results: { name: 'Resultados', path: '/comecar/resultados/' },
  b2bResults: { name: 'Resultados', path: '/resultado-quizz-b2b/' },
  testimonials: { name: 'Depoimentos', path: '/depoimentos/' },
  orderSuccess: { name: 'Pedido realizado', path: '/comecar/compra-realizada/' },

  hcp: { name: 'Sou profissional da saúde', path: '/profissionais-da-saude/' },
  hcpPartners: { name: 'Profissionais da saúde parceiros', path: '/profissionais-da-saude-parceiros/' },
  blog: { name: 'Blog', path: '/artigo/' },
  deepBreathWidget: { name: 'Widget de respiração profunda', path: '/respiracao-profunda/' },
  deepBreathArticle: { name: 'Artigo de respiração profunda', path: '/artigo/respiracao-profunda/' },
  about: { name: 'Sobre nós', path: '/sobre/' },
  terms: { name: 'Termos e condições', path: '/sobre/termos-e-condicoes/' },
  privacyPolicy: { name: 'Política de privacidade', path: '/sobre/politica-de-privacidade/' },
  cookiePolicy: { name: 'Política de cookie', path: '/sobre/politica-de-cookies/' },
  evidences: { name: 'Evidências', path: '/evidencias/' },
  hcpFaq: { name: 'Perguntas frequentes de profissionais de saúde', path: '/perguntas-frequentes-de-profissionais-da-saude/' },
  faq: { name: 'Perguntas frequentes', path: '/perguntas-frequentes/' },
  home: { name: 'Início', path: '/' },
  assesment: { name: 'Quiz gratuito', path: '/comecar/' },
  subscription: { name: 'Preços para pacientes', path: '/comecar/planos/' },
  instructions: { name: 'Conheça a Sônia', path: '/comecar/instrucoes/' },
  covid: { name: 'Conheça a Sônia', path: '/comecar/covid19/' },
  reportSample: { name: 'Exemplo de relatório', path: '/exemplo/relatorio/' },
};

export const appPathsDictionary = Object.keys(appPaths).reduce((acc, curr) => {
  const item = appPaths[curr];
  acc[item.path] = item.name;
  return acc;
}, {});

export const pagesPathsDictionary = Object.keys(pagesPaths).reduce((acc, curr) => {
  const item = pagesPaths[curr];
  acc[item.path] = item.name;
  return acc;
}, {});
