import { NativeHelper } from './native.helper';
import { HttpAuthHeader } from './vigilantes.datasource';

enum NativeAuthHeadersError {
  InvalidCredentialsError = 'Invalid Native Credentials',
}

/**
 * @see PreUserAuthHandler (packages/app.api/src/api/middleware/authentication.middleware.ts) to understand how this Auth works
 * @see AuthHeaders (packages/app.api/src/api/middleware/authentication.middleware.ts)
 */
export class NativeAuthHeaders implements HttpAuthHeader {
  getAuthHeader = async (): Promise<{ 'x-insomniac-token': string }> => {
    const token = NativeHelper.token;
    if (!token) {
      throw new Error(NativeAuthHeadersError.InvalidCredentialsError);
    }
    return {
      'x-insomniac-token': token || '',
    };
  };
}
