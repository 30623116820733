import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import fetch from 'isomorphic-fetch';
import { AuthClientErrors } from './messenger-auth-client.model';
import MessengerUserInfoDataSource from './messenger-user-info.datasource';

const retryIf = (error, operation) => {
  if (error?.toString().includes('2071011')) {
    return false;
  }
  if (error?.toString().includes(AuthClientErrors.InvalidCredentialsError)) {
    return false;
  }

  if (error) {
    console.log('Error: MessengerAuthApolloClient: retryIf -> error: ', operation, error);
  }
  return !!error;
};

const getLinks = (uri) =>
  ApolloLink.from([
    new RetryLink({
      delay: {
        initial: 500,
        max: Infinity,
        jitter: true,
      },
      attempts: {
        retryIf,
        max: 5,
      },
    }),
    // https://github.com/apollographql/apollo-link/issues/541#issuecomment-392166160
    new ApolloLink((operation, forward) => {
      return forward(operation).map((data) => {
        if (data && data.errors && data.errors.length > 0) {
          throw new Error(AuthClientErrors.GraphQLOperationalError);
        }
        return data;
      });
    }),
    authLink,
    new HttpLink({ fetch, uri }),
  ]);

////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * This auth link gets the authentication info from Messenger Extension SDK
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const authLink = setContext(async (_request, _previousContext) => {
  try {
    console.log('DIARY_DEBUG: messenger-auth-client.ts ~ line 72 ~ authLink ~ authLink');
    const userInfo = await MessengerUserInfoDataSource.getUserInfo();
    if (!userInfo.psid || !userInfo.signedRequest) {
      throw new Error(AuthClientErrors.InvalidCredentialsError);
    }
    return {
      headers: {
        // https://github.com/apollographql/react-apollo/issues/763#issuecomment-390935309
        'x-psid': userInfo.psid || '',
        'x-signed-request': userInfo.signedRequest || '',
      },
    };
  } catch (error) {
    console.error('ERROR: messenger-auth-client.ts ~ line 69 ~ authLink ~ error', error);
    throw new Error(AuthClientErrors.InvalidCredentialsError);
  }
});

const buildClient = (uri) =>
  new ApolloClient({
    link: getLinks(uri),
    cache: new InMemoryCache(),
  });

const messengerAuthClient = buildClient(process.env.GATSBY_HASURA_BASE_URL);
export default messengerAuthClient;
