import { getStorage, StorageType } from '@web/data/storage/storage.provider';

const MEDIUM_KEY = 'UTM_MEDIUM';
const SOURCE_KEY = 'UTM_SOURCE';

class UtmDataSource {
  private localStorage = getStorage(StorageType.Session);

  get source() {
    return this.localStorage.get(SOURCE_KEY) || undefined;
  }
  set source(value: string) {
    if (!value) return;
    this.localStorage.put(SOURCE_KEY, value);
  }

  get medium() {
    return this.localStorage.get(MEDIUM_KEY) || undefined;
  }
  set medium(value: string) {
    if (!value) return;
    this.localStorage.put(MEDIUM_KEY, value);
  }
}

export default new UtmDataSource();
