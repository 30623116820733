exports.components = {
  "component---src-modules-about-about-detail-tsx": () => import("./../../../src/modules/about/about-detail.tsx" /* webpackChunkName: "component---src-modules-about-about-detail-tsx" */),
  "component---src-modules-article-article-detail-template-tsx": () => import("./../../../src/modules/article/article-detail-template.tsx" /* webpackChunkName: "component---src-modules-article-article-detail-template-tsx" */),
  "component---src-modules-article-article-list-template-tsx": () => import("./../../../src/modules/article/article-list-template.tsx" /* webpackChunkName: "component---src-modules-article-article-list-template-tsx" */),
  "component---src-modules-session-internal-session-detail-tsx": () => import("./../../../src/modules/session/internal-session-detail.tsx" /* webpackChunkName: "component---src-modules-session-internal-session-detail-tsx" */),
  "component---src-modules-session-session-detail-tsx": () => import("./../../../src/modules/session/session-detail.tsx" /* webpackChunkName: "component---src-modules-session-session-detail-tsx" */),
  "component---src-modules-telehealth-profile-telehealth-profile-native-tsx": () => import("./../../../src/modules/telehealth/profile/telehealth-profile-native.tsx" /* webpackChunkName: "component---src-modules-telehealth-profile-telehealth-profile-native-tsx" */),
  "component---src-modules-telehealth-profile-telehealth-profile-web-tsx": () => import("./../../../src/modules/telehealth/profile/telehealth-profile-web.tsx" /* webpackChunkName: "component---src-modules-telehealth-profile-telehealth-profile-web-tsx" */),
  "component---src-modules-tips-tips-tsx": () => import("./../../../src/modules/tips/tips.tsx" /* webpackChunkName: "component---src-modules-tips-tips-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-chatfuel-cadastro-tsx": () => import("./../../../src/pages/chatfuel/cadastro.tsx" /* webpackChunkName: "component---src-pages-chatfuel-cadastro-tsx" */),
  "component---src-pages-chatfuel-comparador-de-tecnicas-e-fatores-tsx": () => import("./../../../src/pages/chatfuel/comparador-de-tecnicas-e-fatores.tsx" /* webpackChunkName: "component---src-pages-chatfuel-comparador-de-tecnicas-e-fatores-tsx" */),
  "component---src-pages-chatfuel-configuracao-diario-tsx": () => import("./../../../src/pages/chatfuel/configuracao-diario.tsx" /* webpackChunkName: "component---src-pages-chatfuel-configuracao-diario-tsx" */),
  "component---src-pages-chatfuel-configuracao-restricao-do-sono-tsx": () => import("./../../../src/pages/chatfuel/configuracao-restricao-do-sono.tsx" /* webpackChunkName: "component---src-pages-chatfuel-configuracao-restricao-do-sono-tsx" */),
  "component---src-pages-chatfuel-configuracao-tsx": () => import("./../../../src/pages/chatfuel/configuracao.tsx" /* webpackChunkName: "component---src-pages-chatfuel-configuracao-tsx" */),
  "component---src-pages-chatfuel-contato-tsx": () => import("./../../../src/pages/chatfuel/contato.tsx" /* webpackChunkName: "component---src-pages-chatfuel-contato-tsx" */),
  "component---src-pages-chatfuel-diario-do-sono-tsx": () => import("./../../../src/pages/chatfuel/diario-do-sono.tsx" /* webpackChunkName: "component---src-pages-chatfuel-diario-do-sono-tsx" */),
  "component---src-pages-chatfuel-dica-sessao-1-tsx": () => import("./../../../src/pages/chatfuel/dica-sessao-1.tsx" /* webpackChunkName: "component---src-pages-chatfuel-dica-sessao-1-tsx" */),
  "component---src-pages-chatfuel-grafico-objetivo-dia-tsx": () => import("./../../../src/pages/chatfuel/grafico-objetivo-dia.tsx" /* webpackChunkName: "component---src-pages-chatfuel-grafico-objetivo-dia-tsx" */),
  "component---src-pages-chatfuel-grafico-objetivo-noite-tsx": () => import("./../../../src/pages/chatfuel/grafico-objetivo-noite.tsx" /* webpackChunkName: "component---src-pages-chatfuel-grafico-objetivo-noite-tsx" */),
  "component---src-pages-chatfuel-grafico-questionarios-tsx": () => import("./../../../src/pages/chatfuel/grafico-questionarios.tsx" /* webpackChunkName: "component---src-pages-chatfuel-grafico-questionarios-tsx" */),
  "component---src-pages-chatfuel-grafico-restricao-do-sono-tsx": () => import("./../../../src/pages/chatfuel/grafico-restricao-do-sono.tsx" /* webpackChunkName: "component---src-pages-chatfuel-grafico-restricao-do-sono-tsx" */),
  "component---src-pages-chatfuel-lista-de-diario-tsx": () => import("./../../../src/pages/chatfuel/lista-de-diario.tsx" /* webpackChunkName: "component---src-pages-chatfuel-lista-de-diario-tsx" */),
  "component---src-pages-chatfuel-relatorio-medico-tsx": () => import("./../../../src/pages/chatfuel/relatorio-medico.tsx" /* webpackChunkName: "component---src-pages-chatfuel-relatorio-medico-tsx" */),
  "component---src-pages-chatfuel-relatorio-restricao-do-sono-tsx": () => import("./../../../src/pages/chatfuel/relatorio-restricao-do-sono.tsx" /* webpackChunkName: "component---src-pages-chatfuel-relatorio-restricao-do-sono-tsx" */),
  "component---src-pages-chatfuel-sessao-interna-funciona-pra-mim-tsx": () => import("./../../../src/pages/chatfuel/sessao-interna/funciona-pra-mim.tsx" /* webpackChunkName: "component---src-pages-chatfuel-sessao-interna-funciona-pra-mim-tsx" */),
  "component---src-pages-chatfuel-share-tsx": () => import("./../../../src/pages/chatfuel/share.tsx" /* webpackChunkName: "component---src-pages-chatfuel-share-tsx" */),
  "component---src-pages-chatfuel-solucoes-tentadas-tsx": () => import("./../../../src/pages/chatfuel/solucoes-tentadas.tsx" /* webpackChunkName: "component---src-pages-chatfuel-solucoes-tentadas-tsx" */),
  "component---src-pages-chatfuel-teleatendimento-agendar-tsx": () => import("./../../../src/pages/chatfuel/teleatendimento/agendar.tsx" /* webpackChunkName: "component---src-pages-chatfuel-teleatendimento-agendar-tsx" */),
  "component---src-pages-chatfuel-teleatendimento-inicio-tsx": () => import("./../../../src/pages/chatfuel/teleatendimento/inicio.tsx" /* webpackChunkName: "component---src-pages-chatfuel-teleatendimento-inicio-tsx" */),
  "component---src-pages-chatfuel-teleatendimento-inicio-v-2-tsx": () => import("./../../../src/pages/chatfuel/teleatendimento/inicio-v2.tsx" /* webpackChunkName: "component---src-pages-chatfuel-teleatendimento-inicio-v-2-tsx" */),
  "component---src-pages-chatfuel-teleatendimento-registrar-tsx": () => import("./../../../src/pages/chatfuel/teleatendimento/registrar.tsx" /* webpackChunkName: "component---src-pages-chatfuel-teleatendimento-registrar-tsx" */),
  "component---src-pages-chatfuel-ver-diario-tsx": () => import("./../../../src/pages/chatfuel/ver-diario.tsx" /* webpackChunkName: "component---src-pages-chatfuel-ver-diario-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-comecar-assinatura-app-tsx": () => import("./../../../src/pages/comecar/assinatura-app.tsx" /* webpackChunkName: "component---src-pages-comecar-assinatura-app-tsx" */),
  "component---src-pages-comecar-assinatura-app-v-2-tsx": () => import("./../../../src/pages/comecar/assinatura-app-v2.tsx" /* webpackChunkName: "component---src-pages-comecar-assinatura-app-v-2-tsx" */),
  "component---src-pages-comecar-compra-consulta-realizada-tsx": () => import("./../../../src/pages/comecar/compra-consulta-realizada.tsx" /* webpackChunkName: "component---src-pages-comecar-compra-consulta-realizada-tsx" */),
  "component---src-pages-comecar-compra-realizada-app-tsx": () => import("./../../../src/pages/comecar/compra-realizada-app.tsx" /* webpackChunkName: "component---src-pages-comecar-compra-realizada-app-tsx" */),
  "component---src-pages-comecar-compra-realizada-tsx": () => import("./../../../src/pages/comecar/compra-realizada.tsx" /* webpackChunkName: "component---src-pages-comecar-compra-realizada-tsx" */),
  "component---src-pages-comecar-instrucoes-b-2-b-tsx": () => import("./../../../src/pages/comecar/instrucoes-b2b.tsx" /* webpackChunkName: "component---src-pages-comecar-instrucoes-b-2-b-tsx" */),
  "component---src-pages-comecar-instrucoes-doc-tsx": () => import("./../../../src/pages/comecar/instrucoes-doc.tsx" /* webpackChunkName: "component---src-pages-comecar-instrucoes-doc-tsx" */),
  "component---src-pages-comecar-instrucoes-hcp-tsx": () => import("./../../../src/pages/comecar/instrucoes-hcp.tsx" /* webpackChunkName: "component---src-pages-comecar-instrucoes-hcp-tsx" */),
  "component---src-pages-comecar-instrucoes-tsx": () => import("./../../../src/pages/comecar/instrucoes.tsx" /* webpackChunkName: "component---src-pages-comecar-instrucoes-tsx" */),
  "component---src-pages-comecar-planos-tsx": () => import("./../../../src/pages/comecar/planos.tsx" /* webpackChunkName: "component---src-pages-comecar-planos-tsx" */),
  "component---src-pages-comecar-planos-v-2-tsx": () => import("./../../../src/pages/comecar/planos-v2.tsx" /* webpackChunkName: "component---src-pages-comecar-planos-v-2-tsx" */),
  "component---src-pages-comecar-resultados-tsx": () => import("./../../../src/pages/comecar/resultados.tsx" /* webpackChunkName: "component---src-pages-comecar-resultados-tsx" */),
  "component---src-pages-comecar-tsx": () => import("./../../../src/pages/comecar.tsx" /* webpackChunkName: "component---src-pages-comecar-tsx" */),
  "component---src-pages-depoimentos-tsx": () => import("./../../../src/pages/depoimentos.tsx" /* webpackChunkName: "component---src-pages-depoimentos-tsx" */),
  "component---src-pages-evidencias-tsx": () => import("./../../../src/pages/evidencias.tsx" /* webpackChunkName: "component---src-pages-evidencias-tsx" */),
  "component---src-pages-exemplo-diario-do-sono-tsx": () => import("./../../../src/pages/exemplo/diario-do-sono.tsx" /* webpackChunkName: "component---src-pages-exemplo-diario-do-sono-tsx" */),
  "component---src-pages-exemplo-relatorio-tsx": () => import("./../../../src/pages/exemplo/relatorio.tsx" /* webpackChunkName: "component---src-pages-exemplo-relatorio-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-page-pv-xly-44-zra-9-drc-4-lm-93-hiadq-4-o-9-quch-2-d-4-tsx": () => import("./../../../src/pages/landing-page-pv-xly44zra9drc4lm93hiadq4o9quch2d4.tsx" /* webpackChunkName: "component---src-pages-landing-page-pv-xly-44-zra-9-drc-4-lm-93-hiadq-4-o-9-quch-2-d-4-tsx" */),
  "component---src-pages-messenger-app-tsx": () => import("./../../../src/pages/messenger-app.tsx" /* webpackChunkName: "component---src-pages-messenger-app-tsx" */),
  "component---src-pages-onboarding-tsx": () => import("./../../../src/pages/onboarding.tsx" /* webpackChunkName: "component---src-pages-onboarding-tsx" */),
  "component---src-pages-perguntas-frequentes-de-profissionais-da-saude-tsx": () => import("./../../../src/pages/perguntas-frequentes-de-profissionais-da-saude.tsx" /* webpackChunkName: "component---src-pages-perguntas-frequentes-de-profissionais-da-saude-tsx" */),
  "component---src-pages-perguntas-frequentes-tsx": () => import("./../../../src/pages/perguntas-frequentes.tsx" /* webpackChunkName: "component---src-pages-perguntas-frequentes-tsx" */),
  "component---src-pages-pre-user-teleatendimento-agendar-tsx": () => import("./../../../src/pages/pre-user/teleatendimento/agendar.tsx" /* webpackChunkName: "component---src-pages-pre-user-teleatendimento-agendar-tsx" */),
  "component---src-pages-pre-user-teleatendimento-inicio-tsx": () => import("./../../../src/pages/pre-user/teleatendimento/inicio.tsx" /* webpackChunkName: "component---src-pages-pre-user-teleatendimento-inicio-tsx" */),
  "component---src-pages-profissionais-da-saude-parceiros-tsx": () => import("./../../../src/pages/profissionais-da-saude-parceiros.tsx" /* webpackChunkName: "component---src-pages-profissionais-da-saude-parceiros-tsx" */),
  "component---src-pages-profissionais-da-saude-tsx": () => import("./../../../src/pages/profissionais-da-saude.tsx" /* webpackChunkName: "component---src-pages-profissionais-da-saude-tsx" */),
  "component---src-pages-quiz-b-2-b-tsx": () => import("./../../../src/pages/quiz-b2b.tsx" /* webpackChunkName: "component---src-pages-quiz-b-2-b-tsx" */),
  "component---src-pages-quiz-teste-tsx": () => import("./../../../src/pages/quiz-teste.tsx" /* webpackChunkName: "component---src-pages-quiz-teste-tsx" */),
  "component---src-pages-resgate-minicurso-tsx": () => import("./../../../src/pages/resgate-minicurso.tsx" /* webpackChunkName: "component---src-pages-resgate-minicurso-tsx" */),
  "component---src-pages-respiracao-profunda-tsx": () => import("./../../../src/pages/respiracao-profunda.tsx" /* webpackChunkName: "component---src-pages-respiracao-profunda-tsx" */),
  "component---src-pages-resultado-quizz-b-2-b-4-t-umd-21-cctfe-tsx": () => import("./../../../src/pages/resultado-quizz-b2b-4tUmd21Cctfe.tsx" /* webpackChunkName: "component---src-pages-resultado-quizz-b-2-b-4-t-umd-21-cctfe-tsx" */),
  "component---src-pages-resultado-quizz-b-2-b-tsx": () => import("./../../../src/pages/resultado-quizz-b2b.tsx" /* webpackChunkName: "component---src-pages-resultado-quizz-b-2-b-tsx" */),
  "component---src-pages-resultado-quizz-pv-657098-d-6519-bbb-41-b-1-d-8415-d-949385693-f-63458-e-tsx": () => import("./../../../src/pages/resultado-quizz-pv-657098d6519bbb41b1d8415d949385693f63458e.tsx" /* webpackChunkName: "component---src-pages-resultado-quizz-pv-657098-d-6519-bbb-41-b-1-d-8415-d-949385693-f-63458-e-tsx" */),
  "component---src-pages-resultado-quizz-tsx": () => import("./../../../src/pages/resultado-quizz.tsx" /* webpackChunkName: "component---src-pages-resultado-quizz-tsx" */),
  "component---src-pages-sobre-tsx": () => import("./../../../src/pages/sobre.tsx" /* webpackChunkName: "component---src-pages-sobre-tsx" */),
  "component---src-pages-teleconsulta-planos-tsx": () => import("./../../../src/pages/teleconsulta/planos.tsx" /* webpackChunkName: "component---src-pages-teleconsulta-planos-tsx" */)
}

