import * as React from 'react';
import { hasWindow } from '@web/utils/platform';

interface ErrorBoundaryProps {
  errorComponent?: JSX.Element;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

/** https://pt-br.reactjs.org/docs/error-boundaries.html */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static getDerivedStateFromError() {
    // Atualiza o state para que a próxima renderização mostre a UI alternativa.
    return { hasError: true };
  }

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, info: { [x: string]: any }): void {
    console.log('ErrorBoundary -> componentDidCatch -> error, info', error);
    console.log('ErrorBoundary -> componentDidCatch -> info', JSON.stringify(info));

    const Sentry = hasWindow() && (window as any).Sentry;
    if (Sentry) {
      Sentry.configureScope((scope) => {
        Object.keys(info).forEach((key) => {
          scope.setExtra(key, info[key]);
        });
      });
      Sentry.captureMessage(`error-boundary.component ~ componentDidCatch ${error}`);
    }
  }

  render() {
    if (this.state.hasError) {
      return this.props.errorComponent || null;
    }

    return this.props.children;
  }
}
