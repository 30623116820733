import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, Operation } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import fetch from 'isomorphic-fetch';
import { AuthClientErrors } from './messenger-auth-client.model';
import { NativeAuthHeaders } from './vigilantes.datasource.native-auth';

const retryIf = (error, operation) => {
  const errorFullMessage = `${error?.toString()}${error?.message}`;
  if (
    errorFullMessage.includes(AuthClientErrors.InvalidCredentialsError) ||
    errorFullMessage.includes(AuthClientErrors.UnauthorizedError) ||
    errorFullMessage.includes(AuthClientErrors.NoInternetError)
  ) {
    return false;
  }

  if (error) {
    console.log('Error: NativeAuthApolloClient: retryIf -> error: ', operation, error);
  }
  return !!error;
};

const getLinks = (uri) =>
  ApolloLink.from([
    new RetryLink({
      delay: {
        initial: 500,
        max: Infinity,
        jitter: true,
      },
      attempts: {
        retryIf,
        max: 5,
      },
    }),
    // https://github.com/apollographql/apollo-link/issues/541#issuecomment-392166160
    new ApolloLink((operation, forward) => {
      addQueryParam(uri, operation);

      return forward(operation).map((data) => {
        if (data && data.errors && data.errors.length > 0) {
          if (data.errors.length > 1) {
            console.error('ERROR: native-auth-client.ts returnforward', data.errors);
          }
          throw data.errors[0];
        }
        return data;
      });
    }),
    authLink,
    new HttpLink({ fetch, uri }),
  ]);

////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * This auth link gets the authentication info from Messenger Extension SDK
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const authLink = setContext(async (_request, _previousContext) => {
  const headers = await new NativeAuthHeaders().getAuthHeader();
  return { headers };
});

const buildClient = (uri) => {
  return new ApolloClient({
    link: getLinks(uri),
    cache: new InMemoryCache(),
  });
};

const nativeAuthClient = buildClient(process.env.GATSBY_HASURA_BASE_URL);
export default nativeAuthClient;

function addQueryParam(uri: string, operation: Operation) {
  const queryParam = `${uri.includes('?') ? '&' : '?'}opname=${operation.operationName}`;
  if (uri.includes('graphql')) operation.setContext({ uri: uri + queryParam });
}
