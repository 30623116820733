import flashDispatcherService from '@web/atomic/legacy/obj.flash-wrapper/flash-dispatcher.service';
import { hasDocument, hasWindow } from '@web/utils/platform';
import QueryString from 'querystringify';
import { isDevelopment } from '../app.landing-page/src/utils/environment';

/** https://developers.facebook.com/docs/messenger-platform/reference/messenger-extensions-sdk */
declare let MessengerExtensions;
export const messengerExtensionSdkUrl = '//connect.facebook.net/en_US/messenger.Extensions.js';

interface IsMessengerExtensionLoaded {
  onSet: (loaded: boolean) => void;
  loaded: boolean;
}

interface GetUserInfoResponse {
  psid: string;
  signedRequest: string;
}

interface ThreadContext {
  thread_type: string;
  tid: string;
  psid: string;
  signed_request: string;
}

class MessengerUserInfoDataSource {
  private memoUserInfo: GetUserInfoResponse;

  getUserInfo = async () => {
    if (!hasDocument()) return {} as GetUserInfoResponse;

    if (this.memoUserInfo && this.memoUserInfo.psid && this.memoUserInfo.signedRequest) {
      return this.memoUserInfo;
    }

    if (!this.isScriptLoaded(messengerExtensionSdkUrl)) {
      console.log('UserInfoDataSource -> getUserInfo -> isScriptLoaded', messengerExtensionSdkUrl);
      await this.loadScript(messengerExtensionSdkUrl);
    }

    this.memoUserInfo = await this.getGetUserInfoResponse();
    console.log('MessengerUserInfoDataSource -> ', this.memoUserInfo);

    if (!isDevelopment && hasWindow()) {
      /* eslint-disable no-useless-escape */
      // https://stackoverflow.com/a/64067387/3670829
      // remove "psid" query-param (security through obscurity)
      history.replaceState &&
        history.replaceState(null, '', location.pathname + location.search.replace(/[\?&]psid=[^&]+/, '').replace(/^&/, '?'));
      /* eslint-enable no-useless-escape */
    }

    return this.memoUserInfo;
  };

  private async getGetUserInfoResponse() {
    return new Promise<GetUserInfoResponse>((success, error) => {
      const isMessengerExtensionDefined = typeof MessengerExtensions !== 'undefined';
      console.log('isMessengerExtensionDefined', isMessengerExtensionDefined);

      try {
        // global var defined on <script> tag @see html.js
        const isMessengerExtensionLoaded: IsMessengerExtensionLoaded = (window as any).isMessengerExtensionLoaded;
        console.log('isMessengerExtensionLoaded', isMessengerExtensionLoaded?.loaded);

        if (isMessengerExtensionLoaded?.loaded) {
          this.getUserContext(success, error);
        } else {
          isMessengerExtensionLoaded.onSet = (loaded) => {
            console.log('onSet: ', loaded);
            this.getUserContext(success, error);
          };
        }
      } catch (internalError) {
        console.error('UserInfoDataSource -> internalError', internalError);
        this.getUserContext(success, error);
      }
    });
  }

  private getUserContext = (success, error) => {
    // DEV_TIP: to force an id, you can fill the GATSBY_HASURA_DEV_ACCESS env var and put a "psid" query-param with the user_id in the url
    // ps: GATSBY_HASURA_DEV_ACCESS should match HASURA_DEV_ACCESS of the API (located in packages/app.api/.env)
    const devAccesss = process.env.GATSBY_HASURA_DEV_ACCESS;
    if (devAccesss && hasWindow()) {
      const search: Partial<{ psid: string }> = QueryString.parse(hasWindow() ? window.location.search : '');
      const psid = search.psid;
      if (psid) {
        return success({
          psid: search.psid,
          signedRequest: devAccesss,
        });
      }
    }

    const fbAppId = process.env.GATSBY_FB_CHATFUEL_APP_ID;
    return MessengerExtensions.getContext(
      fbAppId,
      (context: ThreadContext) => {
        success({
          psid: context.psid,
          signedRequest: context.signed_request,
        });
      },
      (err) => {
        if (isDevelopment) {
          // hashi
          const psid = process.env.GATSBY_DEVELOPER_PSID;
          const signedRequest = process.env.GATSBY_DEVELOPER_SIGNED_REQUEST;
          success({
            psid,
            signedRequest,
          });
        } else {
          console.error('UserInfoDataSource -> privategetUserContext -> err', err);
          // https://developers.facebook.com/docs/messenger-platform/reference/messenger-extensions-sdk/error-codes/
          if (err === '2071010') {
            flashDispatcherService.dispatchMessage(`Por favor, atualize o aplicativo do Messenger para ter acesso a essa tela.`, 'alert');
          }
          error(err);
        }
      }
    );
  };

  /**
   * https://stackoverflow.com/a/7719185/3670829
   * @param src
   */
  private loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const s = document.createElement('script');
      s.src = src;
      s.onload = resolve;
      s.onerror = reject;
      document.head.appendChild(s);
    });
  };
  private isScriptLoaded = (src) => {
    return document.querySelector(`script[src="${src}"]`) ? true : false;
  };
}

export default new MessengerUserInfoDataSource();
