import '@fortawesome/fontawesome-svg-core/styles.css';
import UtmDataSource from './src/data/utm.datasource';
import VisitCountDataSource from './src/data/visit-count.datasource';
import SessionHistory from './src/utils/browser/session-history';
import { GlobalStyled, ThemeContainer } from '@web/atomic';
import { ApolloProvider } from '@web/data/apollo-client';
import messengerAuthClient from '@web/data/messenger-auth-client';
import nativeAuthClient from '@web/data/native-auth-client';
import { NativeHelper } from '@web/data/native.helper';
import { EventKind, sendEvent } from '@web/utils/analytics';
import 'microtip/microtip.css';
import QueryString from 'querystringify';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import './src/assets/fonts/fonts.css';
import { ErrorBoundary } from './src/components/obj.error-boundary/error-boundary.component';

export const wrapRootElement = ({ element }) => {
  return (
    <ErrorBoundary>
      <ThemeContainer>
        {(theme) => {
          return (
            <ThemeProvider theme={theme}>
              <>
                <Helmet>
                  <html lang="pt-BR" />
                </Helmet>
                <ApolloProvider client={NativeHelper.isNative ? nativeAuthClient : messengerAuthClient}>{element}</ApolloProvider>
              </>
            </ThemeProvider>
          );
        }}
      </ThemeContainer>
    </ErrorBoundary>
  );
};

// https://github.com/gatsbyjs/gatsby/issues/20594#issuecomment-574262809
export const wrapPageElement = ({ element }) => (
  <>
    <GlobalStyled />
    {element}
  </>
);

export const onRouteUpdate = ({ location, prevLocation }) => {
  const search = QueryString.parse(location.search);
  UtmDataSource.source = search.utm_source;
  UtmDataSource.medium = search.utm_medium;
  SessionHistory.handleRouteChange(location);
  VisitCountDataSource.incrementVisitCount();
};

export const onServiceWorkerUpdateReady = () => {
  window.location.reload();
  console.log('onServiceWorkerUpdateReady');
  sendEvent(EventKind.reload, {
    event_category: 'ServiceWorkerUpdateReady',
    event_label: 'version update',
  });
};
