import { appPaths } from '@lp-root/src/utils/path';
enum HistoryAction {
  REPLACE = 'REPLACE',
  PUSH = 'PUSH',
  POP = 'POP',
}

interface RouteLocation {
  pathname: string;
  search: string;
  action: HistoryAction;
}

class SessionHistory {
  private historyStack = [];

  isFirstRoute = () => {
    return this.historyStack.length <= 1;
  };

  getLastRouteName = () => {
    const lastRoute = this.getLastRoute();
    const pathList = Object.values(appPaths);
    const path = pathList.find((item) => item.path === lastRoute);
    return path ? path.name : '';
  };

  getLastRoute = () => {
    const length = this.historyStack.length;
    return length > 1 ? this.historyStack[length - 2] : null;
  };

  handleRouteChange = (nextLocation: RouteLocation) => {
    const action = nextLocation.action;
    const path = `${nextLocation.pathname}${nextLocation.search}`;
    if (!action) {
      this.historyStack.push(path);
      return;
    }

    switch (action) {
      case HistoryAction.PUSH:
        this.historyStack.push(path);
        break;
      case HistoryAction.REPLACE:
        this.historyStack.pop();
        this.historyStack.push(path);
        break;
      case HistoryAction.POP:
        this.historyStack.pop();
        break;
      default:
        console.log('SessionHistory -> handleRouteChange -> nextLocation', nextLocation);
        console.error(`invalid action: ${action}`);
        break;
    }
  };
}

export default new SessionHistory();
